import React, { createContext, useContext } from 'react';

import { endOfDay, endOfMonth, startOfDay } from 'date-fns';
import { collection, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { nanoid } from 'nanoid';

import { convertToDate } from '@components/utils';

import { db } from './auth';

const EscalaApiContext = createContext();

function EscalaApiProvider({ children }) {
  const getEscalas = async (userId) => {
    try {
      const docs = collection(db, `users/${userId}/escala`);

      const q = query(docs, where('status', '!=', 'removed'));

      const escalas = await getDocs(q);

      escalas.docs.sort((a, b) => b.data().creation_date.toDate() - a.data().creation_date.toDate());

      return escalas.docs;
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  const getEscala = async (userId, escalaId) => {
    try {
      const document = doc(db, `users/${userId}/escala/${escalaId}`);

      const gradesRef = collection(db, `users/${userId}/escala/${escalaId}/grades`);

      const result = await getDoc(document);
      const gradesResult = await getDocs(gradesRef);

      if (result.data().status === 'removed') return null;

      const grades = {};

      gradesResult.forEach((g) => {
        grades[g.id] = { ...g.data() };
      });

      return { ...result.data(), grades };
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const createEscala = async (userId, data) => {
    const copy = { ...data };
    const grades = { ...copy.grades };
    try {
      const userDataRef = doc(collection(db, `users/${userId}/escala`));

      delete copy.grades;
      copy.creation_date = serverTimestamp();
      copy.status = 'active';

      await setDoc(userDataRef, copy, { merge: true });

      await Promise.all(
        Object.keys(grades).map(async (k) => {
          const dRef = doc(db, `users/${userId}/escala/${userDataRef.id}/grades`, k);

          await setDoc(dRef, grades[k], { merge: true });
        })
      );

      return { success: true };
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
      return { error: true };
    }
  };

  const createEscalaMedico = async ({ userId, escala }) => {
    try {
      const copy = { ...escala };
      const id = escala.id;

      delete copy.id;

      const dataRef = id
        ? doc(db, `users/${userId}/escala_medico/${id}`)
        : doc(collection(db, `users/${userId}/escala_medico`));

      await setDoc(dataRef, copy, { merge: true });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const getAllEvents = async ({ userId, escalaId }) => {
    try {
      const docRef = collection(db, `users/${userId}/escala/${escalaId}/events`);

      const result = await getDocs(docRef);

      return result.docs.map((d) => ({ ...d.data(), event_id: d.id }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getEvents = async ({ userId, escalaId, year, month, range }) => {
    try {
      const docRef = collection(db, `users/${userId}/escala/${escalaId}/events`);

      const date = range ? startOfDay(range.start) : new Date(year, month, 1);
      const endDate = range ? endOfDay(range.end) : endOfMonth(date);

      const q = query(docRef, where('created_at', '>=', date), where('created_at', '<=', endDate));

      const eventsResult = await getDocs(q);

      const result = eventsResult.docs.map((d) => ({
        ...d.data(),
        id: d.id,
        raw_created_at: d.data().created_at.toDate(),
      }));

      return result;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getEvent = async ({ userId, escalaId, gradeId, inputDate }) => {
    try {
      const docs = collection(db, `users/${userId}/escala/${escalaId}/events`);

      let queryDates = [];

      if (inputDate) {
        const { day, month, year } = inputDate;
        const date = new Date(year, month, day);
        queryDates = [where('created_at', '>=', startOfDay(date)), where('created_at', '<=', endOfDay(date))];
      }

      const q = query(docs, where('grade_id', '==', gradeId), ...queryDates);

      const event = await getDocs(q);

      return event.docs[0];
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getEventById = async ({ userId, escalaId, eventId }) => {
    try {
      const document = doc(db, `users/${userId}/escala/${escalaId}/events/${eventId}`);

      const result = await getDoc(document);

      return { ...result.data(), event_id: result.id };
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getDoctorEvents = async ({ userId, month, year }) => {
    try {
      const docRef = collection(db, `users/${userId}/received_event`);

      const startDate = new Date(year, month, 1);

      const q = query(docRef, where('created_at', '>=', startDate), where('created_at', '<=', endOfMonth(startDate)));

      const result = await getDocs(q);

      const validEvents = await Promise.all(
        result.docs.map(async (doc) => {
          const dt = doc.data();
          const escala = await getEscala(dt.gestor_id, dt.escala_id);

          if (escala?.status === 'active') return doc;

          return null;
        })
      );

      return validEvents.filter(Boolean);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getAllDoctorEvents = async ({ userId }) => {
    try {
      const docRef = collection(db, `users/${userId}/received_event`);

      const result = await getDocs(docRef);

      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getDoctorEventsById = async ({ userId, eventId }) => {
    try {
      const docRef = doc(db, `users/${userId}/received_event`, eventId);

      const result = await getDoc(docRef);

      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getEscalaDoctor = async ({ userId, month, year }) => {
    try {
      const docRef = collection(db, `users/${userId}/escala_medico`);

      const startDate = new Date(year, month, 1);

      const q = query(docRef, where('initialDate', '>=', startDate), where('initialDate', '<=', endOfMonth(startDate)));

      const result = await getDocs(q);

      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getEscalaDoctorById = async ({ userId, escalaId }) => {
    try {
      const docRef = doc(db, `users/${userId}/escala_medico/${escalaId}`);

      const result = await getDoc(docRef);

      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const setVaga = async ({ userId, escalaId, gradeId, vaga }) => {
    try {
      const docs = doc(db, `users/${userId}/escala/${escalaId}/grades`, gradeId);

      await setDoc(docs, { vaga }, { merge: true });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const registerEvent = async ({
    event,
    userId,
    escalaId,
    eventDate,
    eventId,
    remove = false,
    removedId,
    skipMerge = false,
  }) => {
    try {
      const [day, month, year] = eventDate;

      const base = `users/${userId}/escala/${escalaId}/events`;

      const currentEventId = eventId ? doc(db, base, eventId) : doc(collection(db, base));

      const created_at = new Date(Date.UTC(year, month, day));
      created_at.setHours(created_at.getHours() + 3);

      const eventCopy = { ...event };
      delete eventCopy.raw_created_at;

      if (eventId) eventCopy.created_at = convertToDate(event.created_at);

      // Adiciona event_id a todos os doctors
      if (eventCopy.doctors && typeof eventCopy.doctors === 'object') {
        Object.keys(eventCopy.doctors).forEach((doctorId) => {
          eventCopy.doctors[doctorId].event_id = eventId || currentEventId.id;
        });
      }

      await setDoc(
        currentEventId,
        { ...eventCopy, ...(!eventId ? { created_at } : { modified_at: serverTimestamp() }) },
        { ...(skipMerge || remove ? {} : { merge: true }) }
      );

      const doctorId = Object.keys(event.doctors)[0];
      const doctorInfo = eventCopy.doctors[doctorId];
      const doctorEvent = {
        created_at,
        escala_id: escalaId,
        gestor_id: userId,
        grade_id: event.grade_id,
      };

      if (!remove) {
        if (!doctorInfo.team) {
          const doctorRef = doc(db, `users/${doctorId}/received_event`, currentEventId.id);

          await setDoc(doctorRef, doctorEvent);
        } else {
          const memberRef = doc(db, `users/${userId}/team/${doctorId}/events`, currentEventId.id);

          await setDoc(memberRef, doctorEvent);
        }
      } else {
        await deleteDoc(doc(db, `users/${removedId}/received_event`, currentEventId.id));
      }

      return { success: true, eventId: currentEventId.id };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const changeEvent = async ({ event, eventId, escalaId, userId }) => {
    try {
      const docRef = doc(db, `users/${userId}/escala/${escalaId}/events/${eventId}`);

      await setDoc(docRef, event, { merge: true });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const deleteEscala = async ({ userId, escalaId }) => {
    try {
      const docRef = doc(db, `users/${userId}/escala/${escalaId}`);

      const escala = await getEscala(userId, escalaId);

      // removendo todos os gerentes da escala
      const gerentes = await getDocs(collection(db, `users/${userId}/escala/${escalaId}/gerentes`));
      await Promise.all(gerentes.docs.map(async (g) => await deleteGerente({ escalaId, userId, gerenteId: g.id })));

      // removendo todas as vagas
      await Promise.all(
        Object.entries(escala.grades).map(async ([gdId, gd]) => {
          if (!gd.vaga) return;
          await Promise.all(
            Object.values(gd.vaga).map(async (year) => {
              if (!year) return;
              await Promise.all(
                Object.values(year).map(async (month) => {
                  if (!month) return;
                  await Promise.all(
                    Object.values(month).map(async (vagaId) => {
                      try {
                        await deleteDoc(doc(db, `vagas/${vagaId}`));
                      } catch (err) {
                        //
                      }
                    })
                  );
                })
              );
            })
          );

          delete gd.vaga;

          await setDoc(doc(db, `users/${userId}/escala/${escalaId}/grades/${gdId}`), gd);
        })
      );

      await setDoc(docRef, { status: 'removed' }, { merge: true });

      return { success: true };
    } catch (err) {
      console.log(err);

      return { error: err };
    }
  };

  const deleteEscalaMedico = async ({ userId, escalaId }) => {
    try {
      const docRef = doc(db, `users/${userId}/escala_medico/${escalaId}`);

      await deleteDoc(docRef);

      return { success: true };
    } catch (err) {
      console.log(err);

      return { error: err };
    }
  };

  const getGerentes = async ({ userId, escalaId }) => {
    try {
      const gerentesRef = collection(db, `users/${userId}/escala/${escalaId}/gerentes`);

      const result = await getDocs(gerentesRef);

      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const addGerente = async ({ escalaId, userId, gerenteId, creation_date, status }) => {
    try {
      // adicionando o gerente na escala
      const docRef = doc(db, `users/${userId}/escala/${escalaId}/gerentes/${gerenteId}`);
      await setDoc(docRef, {
        history: { [nanoid()]: { created_at: serverTimestamp(), operation: 'adicionado como gerente' } },
      });

      // adicionando a escala na tabela do gerente
      const escalaRef = doc(db, `users/${gerenteId}/escala/${escalaId}`);

      await setDoc(escalaRef, { isManager: true, gestor: userId, creation_date, status });

      return { success: true };
    } catch (err) {
      console.log(err);

      return { error: true };
    }
  };

  const deleteGerente = async ({ escalaId, userId, gerenteId }) => {
    try {
      // removendo a escala da tabela do gerente
      const escalaRef = doc(db, `users/${gerenteId}/escala/${escalaId}`);

      await deleteDoc(escalaRef);

      //removendo o gerente da escala
      const docRef = doc(db, `users/${userId}/escala/${escalaId}/gerentes/${gerenteId}`);

      await deleteDoc(docRef);

      return { success: true };
    } catch (err) {
      console.log(err);

      return { error: true };
    }
  };

  return (
    <EscalaApiContext.Provider
      value={{
        getEscalas,
        getEscala,
        createEscala,
        createEscalaMedico,
        getAllEvents,
        getEvents,
        getEvent,
        getEventById,
        getAllDoctorEvents,
        getDoctorEvents,
        getDoctorEventsById,
        getEscalaDoctor,
        getEscalaDoctorById,
        setVaga,
        registerEvent,
        changeEvent,
        deleteEscala,
        deleteEscalaMedico,
        getGerentes,
        addGerente,
        deleteGerente,
      }}
    >
      {children}
    </EscalaApiContext.Provider>
  );
}

export const useEscala = () => useContext(EscalaApiContext);

export { EscalaApiProvider };
