import React, { createContext, useContext, useState } from 'react';

import {
  addDays,
  endOfMonth,
  format,
  getDay,
  getDaysInMonth,
  isSameDay,
  isWithinInterval,
  parseISO,
  startOfMonth,
  subDays,
} from 'date-fns';

import { convertToDate } from '@components/utils';
import { useAuth, useEscala } from '@context';

const SelectedEscalaContext = createContext();

const dayMap = {
  dom: 0,
  seg: 1,
  ter: 2,
  qua: 3,
  qui: 4,
  sex: 5,
  sab: 6,
};

function SelectedEscalaProvider({ children }) {
  const { getEvents, getEscala, getEscalas, getAllEvents } = useEscala();
  const { user } = useAuth();

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedEscala, setSelectedEscala] = useState();
  const [eventsByDay, setEventsByDay] = useState();
  const [doctorsByGradeAndDay, setDoctorsByGradeAndDay] = useState();
  const [escalas, setEscalas] = useState();
  const [updatingEvents, setUpdatingEvents] = useState(false);

  const clearStates = () => {
    setSelectedCompany();
    setSelectedEscala();
    setEventsByDay();
    setDoctorsByGradeAndDay();
    setEscalas();
  };

  const getNumberOfDays = (refDay) => {
    const firstDayOfMonth = startOfMonth(refDay);
    const firstDayOfWeek = getDay(firstDayOfMonth);

    const lastDayOfMonth = endOfMonth(refDay);
    const lastDayOfWeek = getDay(lastDayOfMonth);

    const difStarts = Array(firstDayOfWeek)
      .fill(null)
      .map((_, index) => subDays(firstDayOfMonth, firstDayOfWeek - index));

    const monthdays = Array(getDaysInMonth(refDay))
      .fill(null)
      .map((_, index) => addDays(firstDayOfMonth, index));

    const difEnds = Array(6 - lastDayOfWeek)
      .fill(null)
      .map((_, index) => addDays(lastDayOfMonth, index + 1));

    return [...difStarts, ...monthdays, ...difEnds];
  };

  const updateEvents = async ({ day }) => {
    if (updatingEvents) return;

    setUpdatingEvents(true);
    setEventsByDay({});

    const escala = selectedEscala;

    if (!escala) {
      setUpdatingEvents(false);
      return;
    }

    const numOfDays = getNumberOfDays(day);
    const startOfCurrentMonth = numOfDays[0];
    const endOfCurrentMonth = numOfDays[numOfDays.length - 1];

    const evts = await getAllEvents({
      userId: escala.gestor ?? user.uid,
      escalaId: escala.escala_id,
    });

    const r = {};

    evts.forEach((ev) => {
      const baseDate = ev.created_at.toDate();
      const doctors = ev.doctors;
      const doctorIds = Object.keys(doctors);

      doctorIds.forEach((doctorId) => {
        const recurrence = doctors[doctorId].recurrence;
        const excludedDates = doctors[doctorId].excluded_dates || [];

        if (recurrence) {
          let currentDate = baseDate;
          let occurrences = 0;

          while (true) {
            if (isWithinInterval(currentDate, { start: startOfCurrentMonth, end: endOfCurrentMonth })) {
              const formattedDate = format(currentDate, 'd-M-yyyy');
              if (!excludedDates.some((date) => isSameDay(convertToDate(date), currentDate))) {
                if (!r[formattedDate]) r[formattedDate] = {};
                if (!r[formattedDate][doctorId]) r[formattedDate][doctorId] = [];
                r[formattedDate][doctorId].push(ev);
              }
            }

            // Incrementar a data de acordo com a recorrência
            switch (recurrence.repeat_each.type) {
              case 'day':
                currentDate = addDays(currentDate, recurrence.repeat_each.value);
                break;
              case 'week':
                const currentDay = getDay(currentDate);
                const nextDayIndex = recurrence.repeat_each.repeat.findIndex((day) => dayMap[day] > currentDay);

                if (nextDayIndex !== -1) {
                  currentDate = addDays(currentDate, dayMap[recurrence.repeat_each.repeat[nextDayIndex]] - currentDay);
                } else {
                  currentDate = addDays(currentDate, 7 - currentDay + dayMap[recurrence.repeat_each.repeat[0]]);
                }
                break;
              case 'month':
                if (recurrence.repeat_each.repeat_montly === 0) {
                  const start = new Date(currentDate);
                  currentDate = new Date(start);
                  currentDate.setMonth(start.getMonth() + 1);
                } else if (recurrence.repeat_each.repeat_montly === 1) {
                  const start = new Date(currentDate);
                  const weekDay = start.getDay();
                  const weekOfMonth = Math.floor((start.getDate() - 1) / 7) + 1;

                  currentDate = new Date(start);
                  currentDate.setMonth(start.getMonth() + 1);
                  currentDate.setDate(1);

                  let count = 0;
                  while (count < weekOfMonth) {
                    if (currentDate.getDay() === weekDay) {
                      count++;
                    }
                    if (count < weekOfMonth) {
                      currentDate.setDate(currentDate.getDate() + 1);
                    }
                  }
                }
                break;
              case 'year':
                currentDate = addDays(currentDate, recurrence.repeat_each.value * 365);
                break;
              default:
                break;
            }

            occurrences += 1;

            if (
              recurrence.ends_on === 'date' &&
              currentDate > parseISO(convertToDate(recurrence.ends_on_date).toISOString())
            ) {
              break;
            }
            if (recurrence.ends_on === 'occurrences' && occurrences >= recurrence.ends_on_ocurrences) {
              break;
            }
            if (recurrence.ends_on === 'never' && currentDate > endOfCurrentMonth) {
              break;
            }
          }
        } else {
          const formattedDate = format(baseDate, 'd-M-yyyy');
          if (!excludedDates.some((date) => isSameDay(convertToDate(date), baseDate))) {
            if (!r[formattedDate]) r[formattedDate] = {};
            if (!r[formattedDate][doctorId]) r[formattedDate][doctorId] = [];
            r[formattedDate][doctorId].push(ev);
          }
        }
      });
    });

    setEventsByDay(r);
    setUpdatingEvents(false);
  };

  const fetchEscalas = async () => {
    const res = await getEscalas(user.uid);

    const ecls = await Promise.all(
      res.map(async (es) => {
        const escalaData = es.data();
        if (escalaData.gestor) {
          let esc = await getEscala(escalaData.gestor, es.id);

          return { ...esc, escala_id: es.id, gestor: escalaData.gestor };
        }

        let esc = await getEscala(user.uid, es.id);

        return { ...esc, escala_id: es.id };
      })
    );

    setEscalas(ecls);
  };

  const updateEscala = async () => {
    const newEscala = await getEscala(selectedEscala.gestor ?? user.uid, selectedEscala.escala_id);

    setSelectedEscala({});

    setSelectedEscala({ ...newEscala, gestor: selectedEscala.gestor, escala_id: selectedEscala.escala_id });
  };

  return (
    <SelectedEscalaContext.Provider
      value={{
        selectedCompany,
        selectedEscala,
        eventsByDay,
        doctorsByGradeAndDay,
        escalas,
        setSelectedCompany,
        setSelectedEscala,
        setEventsByDay,
        setDoctorsByGradeAndDay,
        updateEvents,
        getNumberOfDays,
        updateEscala,
        fetchEscalas,
        clearStates,
      }}
    >
      {children}
    </SelectedEscalaContext.Provider>
  );
}

export const useSelectedEscala = () => useContext(SelectedEscalaContext);

export { SelectedEscalaProvider };
