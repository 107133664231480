import { isDate } from 'date-fns';
import merge from 'deepmerge';

export * from './useDevice';
export * from './brazilian_states';
export * from './masks';
export * from './messageToSharing';
export * from './HideOnScroll';
export * from './robotobase64';

export const accountTypes = ['Gestor', 'Médico', 'Gestor/ Médico'];

export const complexidades = { baixo: 'Baixo', medio: 'Médio', alto: 'Alto' };

export const contratacao = {
  plantao: 'Plantão',
  sobreaviso: 'Sobreaviso',
  ambulatorio: 'Ambulatório',
};

export const formacoes = {
  experiencia: 'Experiência',
  residencia: 'Residência Médica',
  pos: 'Pós-Graduação',
  especialidade: 'Título de Especialidade',
  recemformado: 'Recém-Formado',
};

export const containerMaxWidth = 'md';

export const menuH = 81;
export const footerH = 150;

export const supportUrl = (isDesktop) => `https://${isDesktop ? 'web' : 'api'}.whatsapp.com/send?phone=5511988433679`;

export const getCurrency = (value) =>
  value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : '';

export const validateDate = (value, originalValue) => {
  if (isDate(originalValue)) return originalValue;

  const date = originalValue.replace(/^(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
  const [d, m, y] = date.split('/');

  if (Number(d) > 31) return null;

  if (Number(m) > 12 || Number(m) < 1) return null;

  const newDate = new Date(Number(y), Number(m) - 1, Number(d));

  return newDate;
};

export const prefix = (gender) => {
  let p = 'Dr';

  if (gender === 'feminino') p = `${p}a`;
  if (gender === 'outro') p = `${p}(a)`;

  return `${p}.`;
};

export const listDays = Array(30)
  .fill(null)
  .map((_, index) => index + 1);

export function convertToDate(timestamp) {
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  return new Date(milliseconds);
}

// Função para mesclar eventos com o mesmo grade_id
export const mergeEvents = (events) => {
  const mergedEvents = [];

  events.forEach((event) => {
    const existingEvent = mergedEvents.find((item) => item.grade_id === event.grade_id);
    if (existingEvent) {
      // Mesclar os eventos (a lógica de mesclagem pode variar conforme a necessidade)
      const mergedEvent = merge(existingEvent, event);
      Object.assign(existingEvent, mergedEvent);
    } else {
      mergedEvents.push({ ...event });
    }
  });

  return mergedEvents;
};

export const dayMap = {
  dom: 0,
  seg: 1,
  ter: 2,
  qua: 3,
  qui: 4,
  sex: 5,
  sab: 6,
};

export const checkType = (toCheckVariable) => {
  if (typeof toCheckVariable === 'string') return 'string';

  if (typeof toCheckVariable === 'object' && toCheckVariable !== null && !Array.isArray(toCheckVariable))
    return 'object';

  return 'other';
};